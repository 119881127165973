<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <TestSwishPaymentModal
        ref="test-swish-payment-modal"
        :merchant="local_item"
      />

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY.COMPANY')">

                <b-form-select
                  v-model="item.company_id"
                  :options="company_options">
                </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('SWISH_MERCHANT.CREATED_AT')">

              <b-form-input
                :disabled="true"
                v-model="item.created_at" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('SWISH_MERCHANT.PAYEE_ALIAS')">

                <b-form-input
                  v-model="local_item.payee_alias" />


            </b-form-group>
          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row v-if="local_item.id">

          <b-col cols="8">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('SWISH_MERCHANT.FINALIZE_SETUP')">

              <b-button variant="outline-success" @click.prevent="create_swish_test()">
                {{$t('SWISH_MERCHANT.CREATE_TEST')}}
              </b-button>

            </b-form-group>


          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col cols="6">


          </b-col>
          <b-col cols="6">

            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('close')"
              ref="save_button"
            />

          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import TestSwishPaymentModal from '@/view/pages/ml/swish_merchant/TestSwishPaymentModal.vue';

export default {

  name: 'SwishMerchantEditor',

  props: ['item'],
  emits: ['created', 'updated'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    TestSwishPaymentModal
  },

  watch: {

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        if (this.item) {
          this.local_item = { ...this.item };
        }
      }
    },

    'local_item.company_id'() {

    }
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),

    company_options() {
      return this.companies.map((company) => {
        return {
          text: company.name,
          value: company.company_id
        };
      });
    },

    creating() {
      return this.local_item.id === null || this.local_item.id === undefined;
    },

    

  },


  mounted() {

    if (this.item) {
      this.local_item = { ...this.item };
    }

    if (this.creating) {
      this.local_item = {};
      this.local_item.company_id = this.currentCompanyId;
    }
    
  },

  methods: {

    async create_swish_test() {
      try {
        this.$refs['test-swish-payment-modal'].show();
      }
      catch (err) {
        console.error('create_swish_test error', err);
      }
    },

    async on_submit() {

      if (this.creating) {
        await this.create_swish_merchant();
      }
      else {
        await this.update_swish_merchant();
      }

      this.$refs['save_button'].stop();
    },

    async post_swish_merchant(company_id, payee_alias) {
      try {
        const response = await axios.post(`/swish/merchant/${company_id}`, {
          company_id,
          payee_alias
          });

        if (response.status === 201) {
          return response.data;
        }

        throw `invalid response code ${response.status}`;
      }
      catch (err) {
        throw `catched error: ${err}`;
      }
    },

    async put_swish_merchant(id, payee_alias) {
      try {
        const response = await axios.put(`/swish/merchant/${id}`, {
          payee_alias,
        });

        if (response.status === 201) {
          return response.data;
        }

        throw `invalid response code ${response.status}`;
      }
      catch (err) {
        throw `catched error: ${err}`;
      }
    },


    async create_swish_merchant() {
      try {

        const item = await this.post_swish_merchant(this.local_item.company_id, this.local_item.payee_alias);

        this.local_item.id = item.id;
        this.local_item.status = item.status;
        this.local_item.created_at = item.created_at;

        this.local_item = { ...this.local_item }

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.CREATED'));
        this.$emit('created', item);
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_CREATE'));
      }
    },


    async update_swish_merchant() {
      try {

        await this.put_swish_merchant(this.local_item.company_id, this.local_item.payee_alias);

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.UPDATED'));
        this.$emit('updated', this.local_item);
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_CREATE'));
      }
    },

  },


  data() {
    return {

      local_item: {},

      file: null,

      current_file: null,

      files: {

      },

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
